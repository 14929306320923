<template>
  <div v-if="overview">
    <div
      class="columns is-mobile is-multiline is-size-5 is-size-6-mobile"
      v-if="maximumMarketValue"
    >
      <div class="column">
        <div class="columns is-vcentered is-mobile has-text-dark">
          <div class="column">
            <span class="has-text-weight-semibold">
              <span>Maximum market value</span>
              <a
                class="icon is-size-6 has-text-info"
                :title="maxMarketValueTooltip"
                v-tippy="{ placement: 'right' }"
              >
                <i class="fal fa-info-circle" />
              </a>
            </span>
            <p v-if="excVat" class="has-text-weight-light is-size-7">
              This vehicle’s retail valuations do not include VAT. The
              eligibility for VAT qualification depends on specific criteria,
              which can vary based on factors such as usage and registration.
            </p>
          </div>
          <div class="column is-narrow is-size-6">
            <p class="subtitle has-text-dark is-5 is-size-6-mobile">
              {{ pounds(overview.valuation.upperBound) }}
            </p>
          </div>
        </div>
      </div>
      <div class="column is-12 is-v-paddingless my-2">
        <hr class="is-marginless has-background-soft" />
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-size-5 is-size-6-mobile">
      <div class="column">
        <div
          class="columns is-align-content-stretch is-mobile"
          v-if="permissions.cazanaRetailValue"
        >
          <div class="column">
            <p class="field has-text-dark has-text-weight-semibold">
              <span>Retail</span>
              <a
                class="icon is-size-6 has-text-info"
                :title="cazanaRetailTooltip"
                v-tippy="{ placement: 'right' }"
              >
                <i class="fal fa-info-circle" />
              </a>
            </p>
            <p v-if="excVat" class="is-size-7">
              This vehicle’s retail valuations do not include VAT. The
              eligibility for VAT qualification depends on specific criteria,
              which can vary based on factors such as usage and registration.
            </p>
            <div
              class="is-size-7 is-marginless is-mobile has-text-weight-light mt-4"
              v-if="overview.valuation && permissions.retailerTypeValue"
            >
              <p>Retail franchise</p>
              <p>Retail independent</p>
              <p>Retail supermarket</p>
            </div>
          </div>
          <div
            class="column is-flex is-flex-direction-column is-narrow has-text-right"
          >
            <p class="has-text-dark field is-flex-grow-1">
              {{ pounds(overview.retail) }}
            </p>
            <p class="is-size-7 has-text-weight-light">
              {{ pounds(franchise) }}
            </p>
            <p class="is-size-7 has-text-weight-light">
              {{ pounds(overview.valuation.independent) }}
            </p>
            <p class="is-size-7 has-text-weight-light">
              {{ pounds(overview.valuation.supermarket) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="columns is-mobile is-multiline is-size-5 is-size-6-mobile"
      v-if="permissions.cazanaTradeValue"
    >
      <div class="column is-12 is-v-paddingless my-2">
        <hr class="is-marginless has-background-soft" />
      </div>
      <div class="column has-text-dark has-text-weight-semibold">
        <span class="has-text-dark">Trade</span>
        <a
          class="icon has-text-info"
          :title="tradeTooltip"
          v-tippy="{ placement: 'right' }"
        >
          <i class="fal fa-info-circle is-size-6" />
        </a>
      </div>
      <div class="column is-narrow has-text-right">
        <p class="has-text-dark">{{ pounds(overview.remarket) }}</p>
      </div>
      <div class="column is-12 is-v-paddingless my-2" v-if="youngUsed">
        <hr class="is-marginless has-background-soft" />
      </div>
      <div class="column" v-if="youngUsed">
        <div class="is-inline-block has-text-dark">
          <p class="has-text-weight-semibold">Young-used price</p>
        </div>
      </div>
      <div class="column is-narrow has-text-right" v-if="youngUsed">
        <p>{{ pounds(youngUsed) }}</p>
      </div>
      <div
        class="column is-12 is-v-paddingless my-2"
        v-if="permissions.grossProfit"
      >
        <hr class="is-marginless has-background-soft" />
      </div>
      <div class="column" v-if="permissions.grossProfit">
        <p class="field has-text-dark has-text-weight-semibold">
          <span>Gross profit</span>
          <a
            class="icon is-size-6 has-text-info"
            :title="grossProfitTooltip"
            v-tippy="{ placement: 'right' }"
          >
            <i class="fal fa-info-circle" />
          </a>
        </p>
        <p class="is-size-7 has-text-weight-light" v-if="overview.profit">
          Percentage
        </p>
      </div>
      <div class="column has-text-right" v-if="permissions.grossProfit">
        <div v-if="overview.profit">
          <p class="has-text-dark field">
            {{ pounds(overview.profit.value) }}
          </p>
          <p class="is-size-7 has-text-weight-light">
            {{ percentage(overview.profit.percentage) }}
          </p>
        </div>
        <p class="is-size-6 has-text-grey-light" v-else>Unavailable</p>
      </div>
      <div class="column is-12 is-v-paddingless my-2" v-if="hasOCN">
        <hr class="is-marginless has-background-soft" />
      </div>
      <div class="column" v-if="hasOCN">
        <p class="field has-text-dark has-text-weight-semibold">
          <span>% of OCN</span>
          <a
            class="icon is-size-6 has-text-info"
            :title="ocnTooltip"
            v-tippy="{ placement: 'right' }"
          >
            <i class="fal fa-info-circle" />
          </a>
        </p>
        <p
          class="has-text-weight-light is-size-7"
          v-if="overview.ocn && overview.ocn.onTheRoadPrice"
        >
          {{ overallDepreciationStatus }}
        </p>
        <p
          class="has-text-weight-light is-size-7"
          v-if="overview.ocn && overview.ocn.onTheRoadPrice"
        >
          Original cost new
        </p>
      </div>
      <div class="column is-6 has-text-right" v-if="hasOCN">
        <div v-if="overview.ocn && overview.ocn.onTheRoadPrice">
          <p class="has-text-dark field">
            {{
              overview.ocn && overview.ocn.percentage
                ? percentage(overview.ocn.percentage)
                : '&nbsp;'
            }}
          </p>
          <p
            :class="[
              overallDepreciation ? 'has-text-danger' : 'has-text-success'
            ]"
            class="is-size-7"
          >
            {{
              overview.ocn && overview.ocn.difference
                ? overallDepreciationAmount
                : '&nbsp;'
            }}
          </p>
          <p class="is-size-7 has-text-weight-light">
            {{
              overview.ocn && overview.ocn.onTheRoadPrice
                ? pounds(overview.ocn.onTheRoadPrice)
                : '&nbsp;'
            }}
          </p>
        </div>
        <p class="has-text-grey-light" v-else>Unavailable</p>
      </div>
      <div
        v-if="permissions.stockingInsights"
        class="column is-12 is-v-paddingless my-2"
      >
        <hr class="is-marginless has-background-soft" />
      </div>
      <div v-if="permissions.stockingInsights" class="column has-text-dark has-text-weight-semibold">
        <span class="has-text-dark">Days to sale</span>
        <a
          class="icon has-text-info"
          :title="daysToSaleTooltip"
          v-tippy="{ placement: 'right' }"
        >
          <i class="fal fa-info-circle is-size-6" />
        </a>
      </div>
      <div
        v-if="permissions.stockingInsights"
        class="column is-narrow has-text-right"
      >
        <p class="has-text-dark">{{ daysToSale }}</p>
      </div>
      <div class="column is-12 is-v-paddingless my-2">
        <hr class="is-marginless has-background-soft" />
      </div>
      <div class="column">
        <span class="has-text-weight-semibold">
          <span class="has-text-dark">Market demand</span>
          <a
            v-if="marketDemand"
            class="icon has-text-info"
            :title="marketDemandTooltip"
            v-tippy="{ placement: 'right' }"
          >
            <i class="fal fa-info-circle is-size-6" />
          </a>
        </span>
      </div>
      <div class="column is-narrow has-text-right">
        <p
          v-if="marketDemand"
          class="has-text-dark"
          :class="[`is-${marketDemandColor}`]"
        >
          {{ marketDemandText }}
        </p>
        <p class="has-text-grey-light is-size-6" v-else>Unavailable</p>
      </div>
    </div>
  </div>
  <spinner class="section" v-else />
</template>

<script>
import { formatMoney, formatNumber } from 'accounting'
import { includes } from 'lodash'
import { mapGetters } from 'vuex'
import * as marketDemand from '@/modules/shared/market-demand'
export default {
  name: 'CompanionValuationsTrade',
  components: {
    spinner: () => import('core/components/Spinner')
  },
  computed: {
    ...mapGetters('companion', [
      'namaGrade',
      'overview',
      'daysToSale',
      'stockingDepreciation',
      'year',
      'marketDemand',
      'youngUsed',
      'type'
    ]),
    ...mapGetters('auth', ['permissions']),
    isAppreciating() {
      return includes(this.stockingDepreciation, '+')
    },
    hasOCN() {
      return this.permissions.depreciation && this.overview?.ocn?.onTheRoadPrice
    },
    excVat() {
      return this.type === 'LCV' || this.type === 'HCV'
    },
    overallDepreciation() {
      const { overview } = this
      return overview.ocn && overview.ocn.difference > 0
    },
    overallDepreciationStatus() {
      return this.overallDepreciation ? 'Depreciation' : 'Appreciation'
    },
    overallDepreciationAmount() {
      const symbol = this.overallDepreciation ? '-' : '+'
      return symbol + this.pounds(Math.abs(this.overview.ocn.difference))
    },
    stockingDepreciationStatus() {
      return this.isAppreciating
        ? 'Stocking appreciation'
        : 'Stocking depreciation'
    },
    stockingDepreciationTooltip() {
      const status = this.isAppreciating ? 'Appreciation' : 'Depreciation'
      return `${status} over the days to sale`
    },
    marketDemandColor() {
      return marketDemand.getElementColor(this.marketDemand.score)
    },
    marketDemandText() {
      return marketDemand.getElementText(this.marketDemand.score)
    },
    marketDemandTooltip: () => marketDemand.tooltip,
    maxMarketValueTooltip() {
      return 'Maximum Market Value (MMV) is the upper limit of sold prices we see for this make-model-derivative currently in the UK. MMV captures any market dynamics/volatility/shift and hence can be a more appropriate price in times of significant and rapid market shift'
    },
    cazanaRetailTooltip() {
      return 'The retail value is a median real-time overall market value for this exact vehicle. It is broken down into 3 different prices based on the following seller types: Franchise, Independent and Supermarket.'
    },
    tradeTooltip() {
      return `The trade value for this vehicle in ${
        this.namaGrade === 1 ? 'good' : 'poor'
      } condition.`
    },
    grossProfitTooltip() {
      return 'The gross profit is the amount of profit made if a vehicle was purchased at the trade value and sold at the retail value.'
    },
    ocnTooltip() {
      return 'Original cost new - the Manufacturer RRP of the vehicle when new.'
    },
    daysToSaleTooltip() {
      return 'The average number of days it takes to sell this vehicle.'
    },
    franchise() {
      const { franchise, main } = this.overview.valuation
      return franchise ? franchise : main
    },
    maximumMarketValue() {
      return (
        this.permissions.maximumMarketValue &&
        this.overview.valuation?.upperBound
      )
    }
  },
  methods: {
    pounds(value) {
      return formatMoney(value, '£', 0)
    },
    percentage(value) {
      return `${formatNumber(value, 2)}%`
    }
  }
}
</script>
